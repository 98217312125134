@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 400;
  src: local("Circular Std Book"),
    url("../fonts/circular-std/CircularStdBook.woff") format("woff");
}

@font-face {
  font-family: "Circular";
  font-style: italic;
  font-weight: 400;
  src: local("Circular Std Book Italic"),
    url("../fonts/circular-std/CircularStdBookItalic.woff") format("woff");
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 500;
  src: local("Circular Std Medium"),
    url("../fonts/circular-std/CircularStdMedium.woff") format("woff");
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 400;
  src: local("Circular Std Bold"),
    url("../fonts/circular-std/CircularStdBold.woff") format("woff");
}

@font-face {
  font-family: ProximaNova;
  font-weight: 300;
  src: url(../fonts/proximanova/ProximaNova-Light.ttf);
}
@font-face {
  font-family: ProximaNova;
  font-weight: 400;
  src: url(../fonts/proximanova/ProximaNova-Regular.ttf);
}
@font-face {
  font-family: ProximaNova;
  font-weight: 600;
  src: url(../fonts/proximanova/ProximaNova-SemiBold.ttf);
}
@font-face {
  font-family: ProximaNova;
  font-weight: 700;
  src: url(../fonts/proximanova/ProximaNova-Bold.ttf);
}

@font-face {
  font-family: "CooperHewitt";
  font-style: normal;
  font-weight: normal;
  src: local("CooperHewitt-Book"),
    url("../fonts/cooperhewitt-web/CooperHewitt-Book.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: italic;
  font-weight: normal;
  src: local("CooperHewitt-BookItalic"),
    url("../fonts/cooperhewitt-web/CooperHewitt-BookItalic.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: normal;
  font-weight: 100;
  src: local("CooperHewitt-Thin"),
    url("../fonts/cooperhewitt-web/CooperHewitt-Thin.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: italic;
  font-weight: 100;
  src: local("CooperHewitt-ThinItalic"),
    url("../fonts/cooperhewitt-web/CooperHewitt-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: normal;
  font-weight: 300;
  src: local("CooperHewitt-Light"),
    url("../fonts/cooperhewitt-web/CooperHewitt-Light.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: italic;
  font-weight: 300;
  src: local("CooperHewitt-LightItalic"),
    url("../fonts/cooperhewitt-web/CooperHewitt-LightItalic.woff")
      format("woff");
}
@font-face {
  font-family: "CooperHewitt";
  font-style: normal;
  font-weight: 500;
  src: local("CooperHewitt-Medium"),
    url("../fonts/cooperhewitt-web/CooperHewitt-Medium.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: italic;
  font-weight: 500;
  src: local("CooperHewitt-MediumItalic"),
    url("../fonts/cooperhewitt-web/CooperHewitt-MediumItalic.woff")
      format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: normal;
  font-weight: 600;
  src: local("CooperHewitt-Semibold"),
    url("../fonts/cooperhewitt-web/CooperHewitt-Semibold.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: italic;
  font-weight: 600;
  src: local("CooperHewitt-SemiboldItalic"),
    url("../fonts/cooperhewitt-web/CooperHewitt-SemiboldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: normal;
  font-weight: 700;
  src: local("CooperHewitt-Bold"),
    url("../fonts/cooperhewitt-web/CooperHewitt-Bold.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: italic;
  font-weight: 700;
  src: local("CooperHewitt-BoldItalic"),
    url("../fonts/cooperhewitt-web/CooperHewitt-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: normal;
  font-weight: 900;
  src: local("CooperHewitt-Heavy"),
    url("../fonts/cooperhewitt-web/CooperHewitt-Heavy.woff") format("woff");
}

@font-face {
  font-family: "CooperHewitt";
  font-style: italic;
  font-weight: 900;
  src: local("CooperHewitt-HeavyItalic"),
    url("../fonts/cooperhewitt-web/CooperHewitt-HeavyItalic.woff")
      format("woff");
}

/**
 * Shopify Sans
 */

@font-face {
  font-family: ShopifySans;
  font-weight: 400;
  src: url(../fonts/shopify-sans/ShopifySans--regular.woff2) format("woff2");
}
@font-face {
  font-family: ShopifySans;
  font-weight: 500;
  src: url(../fonts/shopify-sans/ShopifySans--medium.woff2) format("woff2");
}
@font-face {
  font-family: ShopifySans;
  font-weight: 700;
  src: url(../fonts/shopify-sans/ShopifySans--bold.woff2) format("woff2");
}
@font-face {
  font-family: ShopifySans;
  font-weight: 800;
  src: url(../fonts/shopify-sans/ShopifySans--extrabold.woff2) format("woff2");
}

@font-face {
  font-family: Patron;
  font-weight: 300;
  src: url(../fonts/patron-web/PatronWEB-Light.woff2) format("woff2"),
    url(../fonts/patron-web/PatronWEB-Light.woff) format("woff");
}
@font-face {
  font-family: Patron;
  font-weight: 400;
  src: url(../fonts/patron-web/PatronWEB-Regular.woff2) format("woff2"),
    url(../fonts/patron-web/PatronWEB-Regular.woff) format("woff");
}
@font-face {
  font-family: Patron;
  font-weight: 600;
  src: url(../fonts/patron-web/PatronWEB-Medium.woff2) format("woff2"),
    url(../fonts/patron-web/PatronWEB-Medium.woff) format("woff");
}
@font-face {
  font-family: Patron;
  font-weight: 700;
  src: url(../fonts/patron-web/PatronWEB-Bold.woff2) format("woff2"),
    url(../fonts/patron-web/PatronWEB-Bold.woff) format("woff");
}
